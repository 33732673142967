<template>
  <v-container>
    <v-row class="title-sticky">
      <v-col cols="12" lg="12">
        <TitleSection
          :title="`${recoverTitle[lang]}`"
          :subtitle="`${recoverSubTitle[lang]}`"
        ></TitleSection>
      </v-col>
    </v-row>
    <v-form ref="formRestoreAccount" class="py-4" @submit.prevent="restore">
      <v-container>
        <v-row :style="{ justifyContent: 'center' }">
          <!-- DOCUMENT -->
          <v-col v-if="token" cols="12" lg="4" md="4" sm="12">
            <v-row v-if="!success">
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0 mb-3">
                <h4
                  v-html="
                    greetingMessage[lang].replace('{names}', payload.nombres)
                  "
                ></h4>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-alert
                  v-if="error.visible"
                  border="left"
                  class="readable"
                  dense
                  :type="error.type"
                >
                  <strong>{{ error[lang] }}</strong>
                </v-alert>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-text-field
                  :label="emailText[lang]"
                  :rounded="rounded"
                  :color="color"
                  class="readable"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="ruleEmail"
                  :disabled="true"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :autocomplete="autocomplete"
                  v-model="payload.email"
                  :solo="solo"
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-text-field
                  :label="passwordText[lang]"
                  :rounded="rounded"
                  :color="color"
                  class="readable"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="passwordRule"
                  :disabled="disabled"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :autocomplete="autocomplete"
                  v-model="payload.password"
                  :solo="solo"
                  :type="passwordType ? 'password' : 'text'"
                  :append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="passwordType = !passwordType"
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-text-field
                  :label="passwordConfirmText[lang]"
                  :rounded="rounded"
                  :color="color"
                  class="readable"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="passwordRule"
                  :disabled="disabled"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :autocomplete="autocomplete"
                  v-model="payload.password2"
                  :type="passwordType ? 'password' : 'text'"
                  :solo="solo"
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                </v-text-field>
                <v-alert
                  v-if="!passwordMatch"
                  border="left"
                  class="readable"
                  outlined
                  dense
                  type="error"
                >
                  <strong>{{ labels.errorMessages.passwords[lang] }}</strong>
                </v-alert>
                <v-alert v-else border="left" dense outlined type="success">
                  <strong>{{ labels.successMessages.passwords[lang] }}</strong>
                </v-alert>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-btn
                  class="d-block w-100 readable"
                  :disabled="loading || !passwordMatch"
                  :loading="loading"
                  dark
                  depressed
                  type="submit"
                  color="tertiary"
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                  {{ restoreLabel[lang] }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-alert border="left" class="" dense :type="'success'">
                  <strong>{{
                    successMessage[lang].replace("{names}", payload.nombres)
                  }}</strong>
                </v-alert>
                <p class="text-center">
                  <small>{{ closeMessage[lang] }}</small>
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-else cols="12" lg="6" md="6" sm="12">
            <v-alert border="left" class="readable" dense :type="error.type">
              <strong>{{ errorToken[lang] }}</strong>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import baseConfig from "@/components/forms/baseConfig";
import { mapGetters, mapActions } from "vuex";
import { emailValidator } from "@/libs/validators.js";
import TitleSection from "@/components/titles/TitleSection.vue";
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    TitleSection,
  },
  data() {
    return {
      ...baseConfig,
      passwordType: true,
      success: false,
      token: "",
      ruleEmail: [this.validateEmail],
      passwordRule: [this.validateRequired, this.validatePasswordLength],
      error: {
        type: "error",
        visible: false,
        en: "Your password could not be updated, please try again later.",
        es: "No se pudo actualizar tu contraseña, inténtalo más tarde.",
      },
      errorToken: {
        en:
          "The URL is invalid or has expired. If the problem persists, request a new account reset URL.",
        es:
          "La URL es inválida o ha caducado. Si el problema persiste solicita una nueva URL de restablecimiento de cuenta.",
      },
      successMessage: {
        en: "Dear {names}, your password has been successfully updated.",
        es: "Estimado {names}, su contraseña se actualizó correctamente.",
      },
      closeMessage: {
        en: "You can now close this window.",
        es: "Ya puedes cerrar esta ventana.",
      },
      labels: {
        requiredLabel: {
          en: "Required field",
          es: "Campo requerido",
        },
        passwordLength: {
          en: "Password must contain at least 6 characters",
          es: "La contraseña debe contener al menos 6 caracteres",
        },
        errorMessages: {
          passwords: {
            en: "Passwords do not match",
            es: "Las contraseñas no coinciden",
          },
        },
        successMessages: {
          passwords: {
            en: "Passwords match",
            es: "Las contraseñas coinciden",
          },
        },
      },
      greetingMessage: {
        en:
          "Hello <strong>{names}</strong>, enter your new password to reset your account.",
        es:
          "Hola <strong>{names}</strong>, ingresa tu nueva contraseña para restablecer tu cuenta.",
      },
      invalidEmail: {
        en: "Wrong email",
        es: "Correo incorrecto",
      },
      loading: false,
      payload: {
        email: "",
        password: "",
        password2: "",
        nombres: "",
        apellidos: "",
        id: 0,
      },
      recoverTitle: {
        en: "RESTORE PASSWORD",
        es: "RESTABLECER CONTRASEÑA",
      },
      recoverSubTitle: {
        en: "Complete the form to reset your account.",
        es: "Completa el formulario para restablecer tu cuenta.",
      },
      emailText: {
        en: "E-mail",
        es: "Correo electrónico",
      },
      passwordText: {
        en: "Password",
        es: "Contraseña",
      },
      passwordConfirmText: {
        en: "Confirm Password",
        es: "Confirmar Contraseña",
      },
      restoreLabel: {
        en: "Restore account",
        es: "Restablecer cuenta",
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
    passwordMatch() {
      return this.payload.password && this.payload.password == this.payload.password2;
    }
  },
  mounted() {
    const { token } = this.$route.query;
    const decoded = jwtDecode(token);

    if (!decoded) {
      // show error message
      return;
    }
    
    const expDate =  new Date(decoded.expire * 1000);
    const actualDate = new Date();

    if(actualDate > expDate) return;
    

    this.token = token;

    const { data: userData } = decoded;
    this.payload = {
      email: userData.CORREO,
      password: "",
      password2: "",
      nombres: userData.NOMBRES,
      apellidos: userData.APELLIDOS,
      id: userData.IDUSUARIO,
    };
  },
  methods: {
    ...mapActions({}),
    validateRequired(v) {
      if (!v) return this.labels.requiredLabel[this.lang];
      return true;
    },
    validatePasswordLength(v) {
      if (v && v.length < 6) return this.labels.passwordLength[this.lang];
      return true;
    },
    validateEmail(v) {
      return emailValidator(v) || this.invalidEmail[this.lang];
    },
    validateForm() {
      return this.$refs.formRestoreAccount.validate();
    },
    async restore() {
      if (!this.validateForm()) return;
      this.loading = true;
      const res = await this.$store.dispatch("restorePassword", {
        idUsuario: this.payload.id,
        correo: this.payload.email,
        password: this.payload.password,
      });
      this.loading = false;
      if (!res) return;
      this.success = true;
    },
  },
};
</script>

<style>
.v-alert.readable {
  margin-bottom: 2em;
}
</style>
